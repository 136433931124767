<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-expansion-panels accordion focusable>
        <v-expansion-panel
          v-for="(panel, index) in panels"
          :key="index"
          v-if="formattedPanel.some(formatted => formatted === panel.title)"
        >
          <v-expansion-panel-header :disable-icon-rotate="panel.icon != '$expand'">
            {{ panel.title }}
            <template v-slot:actions>
              <!-- <v-icon :color="panel.icon == 'mdi-check' ? 'teal' : 'primary'">
                {{ panel.icon }}
              </v-icon> -->
              <v-icon class="ml-3" @click="removeBilledItem(panel.title)" style="color: #e61721;">
                mdi-close
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-5">
            <component :is="panel.component" :services-opted="items" :operation="operation" :isDraft="isDraft"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <ReportDetails :operation="operation" />
     <!--  <v-btn color="primary" class="mt-7" outlined @click="$router.push({name: 'showOperationReport', params: {id: operation.id}})">
        Show Job Details
      </v-btn>
      <v-btn color="primary" class="mt-7" outlined @click="$router.push({name: 'showOperationQuote', params: {id: operation.id, type: 'Quote'}})" v-if="$root.hasAccess('can_manage_operation_amount_details')">
        Show Quote Details
      </v-btn> -->
      <v-btn style="background-color: #273978; color: white;" class="mt-7 float-right" outlined @click="formSubmit">
        Submit
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../utilities/validator';
  import AgencyFees from './services-opted/AgencyFees';
  import BLFee from './services-opted/BLFee';
  import Bunker from './services-opted/Bunker';
  import BunkerSurveyor from './services-opted/BunkerSurveyor';
  import CashToMaster from './services-opted/CashToMaster';
  import ClassSurveyor from './services-opted/ClassSurveyor';
  import CoordinationFee from './services-opted/CoordinationFee';
  import Courier from './services-opted/Courier';
  import CrewSignOnService from './services-opted/CrewSignOnService';
  import CrewSignOffService from './services-opted/CrewSignOffService';
  import Deslopping from './services-opted/Deslopping';
  import Fumigation from './services-opted/Fumigation';
  import Freshwater from './services-opted/Freshwater';
  import GarbageDisposal from './services-opted/GarbageDisposal';
  import HandlingOfSpares from './services-opted/HandlingOfSpares';
  import HotelBookingCrewSignOn from './services-opted/HotelBookingCrewSignOn';
  import HotelBookingCrewSignOff from './services-opted/HotelBookingCrewSignOff';
  import HotelBookingForSuperintendent from './services-opted/HotelBookingForSuperintendent';
  import LaunchBoatPassengerBoat from './services-opted/LaunchBoatPassengerBoat';
  import LaunchBoatSupplyBoat from './services-opted/LaunchBoatSupplyBoat';
  import MedicalCheckupCrewSignOn from './services-opted/MedicalCheckupCrewSignOn';
  import Others from './services-opted/Others';
  import PilotServiceInward from './services-opted/PilotServiceInward';
  import PilotServiceOutward from './services-opted/PilotServiceOutward';
  import PortClearanceAndDues from './services-opted/PortClearanceAndDues';
  import Porterage from './services-opted/Porterage';
  import PrePurchaseInspection from './services-opted/PrePurchaseInspection';
  import PreVettingInspection from './services-opted/PreVettingInspection';
  import Repair from './services-opted/Repair';
  import SSCEC from './services-opted/SSCEC';
  import Supplies from './services-opted/Supplies';
  import Telecommunication from './services-opted/Telecommunication';
  import TransportService from './services-opted/TransportService';
  import TugBoatArrival from './services-opted/TugBoatArrival';
  import TugBoatDeparture from './services-opted/TugBoatDeparture';
  import UnderwaterCleaning from './services-opted/UnderwaterCleaning';
  import UnderwaterInspection from './services-opted/UnderwaterInspection';
  import VisaApplicationCrewSignOn from './services-opted/VisaApplicationCrewSignOn';
  import VisaApplicationCrewSignOff from './services-opted/VisaApplicationCrewSignOff';
  import WritOfSummons from './services-opted/WritOfSummons';
  import _ from 'lodash';
  import ReportDetails from '../ReportDetails';

  export default {
    name: 's-services-opted',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      AgencyFees,
      BLFee,
      Bunker,
      BunkerSurveyor,
      CashToMaster,
      ClassSurveyor,
      CoordinationFee,
      Courier,
      CrewSignOnService,
      CrewSignOffService,
      Deslopping,
      Freshwater,
      Fumigation,
      GarbageDisposal,
      HandlingOfSpares,
      HotelBookingCrewSignOn,
      HotelBookingCrewSignOff,
      HotelBookingForSuperintendent,
      LaunchBoatPassengerBoat,
      LaunchBoatSupplyBoat,
      MedicalCheckupCrewSignOn,
      Others,
      PilotServiceInward,
      PilotServiceOutward,
      PortClearanceAndDues,
      Porterage,
      PrePurchaseInspection,
      PreVettingInspection,
      Repair,
      SSCEC,
      Supplies,
      Telecommunication,
      TransportService,
      TugBoatArrival,
      TugBoatDeparture,
      UnderwaterCleaning,
      UnderwaterInspection,
      VisaApplicationCrewSignOn,
      VisaApplicationCrewSignOff,
      WritOfSummons,
      ReportDetails
    },
    data() {
      return {
        panels: [
          {
            title: 'Agency Fees',
            icon: '$expand',
            component: 'AgencyFees'
          },
          {
            title: 'B/L Fee',
            icon: '$expand',
            component: 'BLFee'
          },
          {
            title: 'Bunker Service',
            icon: '$expand',
            component: 'Bunker'
          },
          {
            title: 'Bunker Surveyor',
            icon: '$expand',
            component: 'BunkerSurveyor'
          },
          {
            title: 'Cash To Master',
            icon: '$expand',
            component: 'CashToMaster'
          },
          {
            title: 'Class Surveyor',
            icon: '$expand',
            component: 'ClassSurveyor'
          },
          {
            title: 'Coordination Fee',
            icon: '$expand',
            component: 'CoordinationFee'
          },
          {
            title: 'Courier',
            icon: '$expand',
            component: 'Courier'
          },
          {
            title: 'Crew Change',
            icon: '$expand',
            component: 'CrewChange'
          },
          {
            title: 'Crew Sign On Service',
            icon: '$expand',
            component: 'CrewSignOnService'
          },
          {
            title: 'Crew Sign Off Service',
            icon: '$expand',
            component: 'CrewSignOffService'
          },
          {
            title: 'Deslopping',
            icon: '$expand',
            component: 'Deslopping'
          },
          {
            title: 'Freshwater',
            icon: '$expand',
            component: 'Freshwater'
          },
          {
            title: 'Fumigation',
            icon: '$expand',
            component: 'Fumigation'
          },
          {
            title: 'Garbage Disposal',
            icon: '$expand',
            component: 'GarbageDisposal'
          },
          {
            title: 'Handling of Spares',
            icon: '$expand',
            component: 'HandlingOfSpares'
          },
          {
            title: 'Hotel Booking Crew Sign On',
            icon: '$expand',
            component: 'HotelBookingCrewSignOn'
          },
          {
            title: 'Hotel Booking Crew Sign Off',
            icon: '$expand',
            component: 'HotelBookingCrewSignOff'
          },
          {
            title: 'Hotel Booking Superintendent or Owners representatives',
            icon: '$expand',
            component: 'HotelBookingForSuperintendent'
          },
          {
            title: 'Launch Boat Passenger Boat',
            icon: '$expand',
            component: 'LaunchBoatPassengerBoat'
          },
          {
            title: 'Launch Boat Supply Boat',
            icon: '$expand',
            component: 'LaunchBoatSupplyBoat'
          },
          {
            title: 'Medical Consultation',
            icon: '$expand',
            component: 'MedicalCheckupCrewSignOn'
          },
          {
            title: 'Others',
            icon: '$expand',
            component: 'Others'
          },
          {
            title: 'Pilot Service Inward',
            icon: '$expand',
            component: 'PilotServiceInward'
          },
          {
            title: 'Pilot Service Outward',
            icon: '$expand',
            component: 'PilotServiceOutward'
          },
          {
            title: 'Port Clearance And Dues',
            icon: '$expand',
            component: 'PortClearanceAndDues'
          },
          {
            title: 'Porterage',
            icon: '$expand',
            component: 'Porterage'
          },
          {
            title: 'Pre Purchase Inspection',
            icon: '$expand',
            component: 'PrePurchaseInspection'
          },
          {
            title: 'Pre Vetting Inspection',
            icon: '$expand',
            component: 'PreVettingInspection'
          },
          {
            title: 'Repair',
            icon: '$expand',
            component: 'Repair'
          },
          {
            title: 'SSCEC',
            icon: '$expand',
            component: 'SSCEC'
          },
          {
            title: 'Supplies',
            icon: '$expand',
            component: 'Supplies'
          },
          {
            title: 'Telecommunication',
            icon: '$expand',
            component: 'Telecommunication'
          },
          {
            title: 'Tug Boat Arrival',
            icon: '$expand',
            component: 'TugBoatArrival'
          },
          {
            title: 'Tug Boat Departure',
            icon: '$expand',
            component: 'TugBoatDeparture'
          },
          {
            title: 'Transport Service',
            icon: '$expand',
            component: 'TransportService'
          },
          {
            title: 'Underwater Cleaning',
            icon: '$expand',
            component: 'UnderwaterCleaning'
          },
          {
            title: 'Underwater Inspection',
            icon: '$expand',
            component: 'UnderwaterInspection'
          },
          {
            title: 'Visa Application Crew Sign On',
            icon: '$expand',
            component: 'VisaApplicationCrewSignOn'
          },
          {
            title: 'Visa Application Crew Sign Off',
            icon: '$expand',
            component: 'VisaApplicationCrewSignOff'
          },
          {
            title: 'Writ of Summons',
            icon: '$expand',
            component: 'WritOfSummons'
          },
        ],
        form: false,
        rules,
        loading: false,
        services: [],
        // suppliers: [],
        items: [],
        formattedPanel: [],
        search: '',
        tableLoading: false,
        serviceOptForm: {
          service_catalog_id: null,
          card_id: null,
        },
      };
    },
    methods: {
      formSubmit(){
        if (this.$root.hasAccess('can_manage_fda') && !this.operation.is_draft){
          this.$emit('nextStep', 4, this.operation);
        } else {
          this.$emit('dialogClose');
        }
      },
      handleOperationInit() {
        this.loadAllServicesOpted();
      },
      removeBilledItem(item) {
        this.$api.operation_billed_items.destroy(item,this.operation.id)
          .then((response) => {
            this.loadAllServicesOpted();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      // populateSuppliers(service_id) {
      //   this.$api.service_catalogs.get_suppliers_list(service_id)
      //     .then((response) => {
      //       this.suppliers = response.suppliers;
      //     }).catch((err) => {
      //       this.$store.dispatch('setAlert', {
      //         status: 'error',
      //         show: true,
      //         message: err.message,
      //       });
      //     });
      // },
      submit() {
        return this.$refs.form.validate();
      },
      updateStatus() {
        this.panels.forEach((item, index) => {
          let selectedItem = _.find(this.items, {service_name: item.title});
          if (selectedItem) {
            this.panels[index].icon = 'mdi-check';
          } else {
            this.panels[index].icon = '$expand';
          }
        })
      },
      loadAllServicesOpted() {
        if(!this.operation.id) return;

        this.tableLoading = true;
        this.$api.operation_billed_items.index(this.operation.id)
          .then((response) => {
            this.items = response.suppliers;
            this.operation.approved_by = response.operation?.approved_by;
            this.formattedPanel = response.suppliers.map(item => item.service_name);
            this.updateStatus();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.tableLoading = false));
      },
      saveDetails() {
        if(!this.submit() || !this.operation.id) return;

        this.loading = true;
        this.serviceOptForm.operation_id = this.operation.id;
        this.$api.operation_billed_items.create(this.serviceOptForm)
          .then((response) => {
            this.loadAllServicesOpted();
            this.serviceOptForm = {
              service_catalog_id: null,
              card_id: null,
            };
            this.$refs.form && this.$refs.form.resetValidation();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      }
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>