<template>
  <div class="text-center">
    <v-dialog v-model="showForm" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{action}}
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3 pb-0">
          <v-autocomplete
            label="Search Vessel By Name"
            placeholder="Enter Vessel Name"
            outlined
            dense
            clearable
            :search-input.sync="searchName"
            :items="vessels"
            v-model="vesselName"
            prepend-inner-icon="mdi-ferry"
            hide-no-data
            hide-selected
            item-text="vesselName"
            item-value="vesselName"
            :loading="searchLoading"
          ></v-autocomplete>
        </v-card-text>
        <v-card-text class="pb-2" v-if="vesselInfo.imoNumber">
          <div class="headline">Vessel Details:</div>
          <div>
            <v-row>
              <v-col cols="6" class="pb-0">Name</v-col>
              <v-col cols="6" class="pb-0">{{vesselInfo.vesselName}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pb-0">Vessel Type</v-col>
              <v-col cols="6" class="pb-0">{{vesselInfo.vesselType}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pb-0">IMO Number</v-col>
              <v-col cols="6" class="pb-0">{{vesselInfo.imoNumber}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pb-0">Call Sign</v-col>
              <v-col cols="6" class="pb-0">{{vesselInfo.callSign}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pb-0">Flag</v-col>
              <v-col cols="6" class="pb-0">{{vesselInfo.flag}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pb-0">Registered Ownership</v-col>
              <v-col cols="6" class="pb-0">{{vesselInfo.registeredOwnership}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pb-6">Gross Tonnage</v-col>
              <v-col cols="6" class="pb-6">{{vesselInfo.grossTonnage}}</v-col>
            </v-row>
          </div>
          <v-text-field
            label="Email"
            placeholder="Enter Email Address"
            :rules="[rules.email]"
            outlined
            dense
            v-model="email"
            prepend-inner-icon="mdi-email"
            clearable
          ></v-text-field>
          <v-text-field
            label="Mobile"
            placeholder="Enter Mobile Number"
            :rules="[rules.mobile]"
            outlined
            dense
            v-model="vessel_contact"
            prepend-inner-icon="mdi-phone"
            clearable
          ></v-text-field>
          <v-text-field
            label="Parent Company"
            placeholder="Enter Parent Company"
            outlined
            dense
            v-model="parentComapny"
            prepend-inner-icon="mdi-office-building"
            clearable
          ></v-text-field>
          <v-select
              label="Vessel Owner"
              placeholder="Select vessel owner"
              outlined
              multiple
              dense
              :items="filteredOwners"
              :rules="[rules.required]"
              v-model="vesselOwner"
              prepend-inner-icon="mdi-account"
              item-text="name"
              item-value="id"
            >
              <template v-slot:prepend-item>
                <v-text-field
                  v-model="searchQuery"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Search..."
                  clearable
                  @input="filterOwners"
                ></v-text-field>
              </template>
            </v-select>
        </v-card-text>
        <v-card-actions class="justify-center" v-if="vesselInfo.imoNumber">
          <v-btn color="primary" outlined @click="formSubmit()" 
              :disabled="formLoading || !vesselOwner" :loading="formLoading">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import rules from '../../../utilities/validator';

  export default {
    name: 's-vessel-api-form',
    props: {
      action: {
        type: String,
        required: true,
      },
      owners: Array,
      showForm: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        rules,
        searchLoading: false,
        searchName: '',
        vesselName: '',
        vesselInfo: {},
        vessels: [],
        vesselOwner: '',
        email: '',
        vessel_contact: '',
        formLoading: false,
        parentComapny: '',
        searchQuery: '',
        filteredOwners: this.owners,
      };
    },
    methods: {
      filterOwners() {
        if (!this.searchQuery) {
          this.filteredOwners = this.owners;
        } else {
          this.filteredOwners = this.owners.filter(owner =>
            owner.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        }
      },
      formSubmit() {
        let vesselObj = {
          name: this.vesselInfo.vesselName,
          vessel_type: this.vesselInfo.vesselType,
          imo_number: this.vesselInfo.imoNumber,
          call_sign: this.vesselInfo.callSign,
          flag: this.vesselInfo.flag,
          registered_ownership: this.vesselInfo.registeredOwnership,
          gross_tonnage: this.vesselInfo.grossTonnage,
          card_id: this.vesselOwner,
          email: this.email,
          vessel_contact: this.vessel_contact,
          parent_company: this.parentComapny,
        };
        this.formLoading = true;
        this.$api.vessels.create(vesselObj)
          .then((response) => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Vessel created successfully',
            });
            this.$emit('formSubmitted', response.vessel);
          }).catch((err) => {0
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.formLoading = false));
      },
    },
    watch: {
      searchName(value) {
        if (value == this.vesselName) return;
        if (!value || !value.trim().length || value.trim().length <= 2 || value.trim().length > 8) return;
        this.vessels = [];
        this.searchLoading = true;
        this.$api.vessels.findVessel(value.trim())
          .then((response) => {
            if(response.vessels.length) this.vessels = response.vessels;
          }).catch((err) => {0
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.searchLoading = false));
      },
      vesselName(value) {
        this.$api.vessels.getVesselInfo(value)
          .then((response) => {
            this.vesselInfo = response.vessel[0];
          }).catch((err) => {0
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
    },
  };
</script>