<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-row align="center">
            <v-col cols="auto">
              <v-checkbox
                v-model="hotelBookingDeparture.metadata.singleSharing"
                label="Single Sharing"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="auto">
              <v-text-field
                v-if="hotelBookingDeparture.metadata.singleSharing"
                class="ml-8"
                label="No. Of Crews for Single Sharing"
                placeholder="Enter No. Of Crews for Single Sharing"
                :rules="[rules.negative]"
                outlined
                dense
                type="number"
                v-model="hotelBookingDeparture.metadata.no_of_single_sharing_crews"
                prepend-inner-icon="mdi-account"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-row align="center">
            <v-col cols="auto">
              <v-checkbox
                v-model="hotelBookingDeparture.metadata.doubleSharing"
                label="Double Sharing"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="auto">
              <v-text-field
                v-if="hotelBookingDeparture.metadata.doubleSharing"
                class="ml-6"
                label="No. Of Crews for Double Sharing"
                placeholder="Enter No. Of Crews for Double Sharing"
                :rules="[rules.negative]"
                outlined
                dense
                type="number"
                v-model="hotelBookingDeparture.metadata.no_of_double_sharing_crews"
                prepend-inner-icon="mdi-account"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="pb-0 mb-5">
          <v-row align="center">
            <v-col cols="auto">
              <v-checkbox
                v-model="hotelBookingDeparture.metadata.tripleSharing"
                label="Triple Sharing"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="auto">
              <v-text-field
                v-if="hotelBookingDeparture.metadata.tripleSharing"
                class="ml-9"
                label="No. Of Crews for Triple Sharing"
                placeholder="Enter No. Of Crews for Triple Sharing"
                :rules="[rules.negative]"
                outlined
                dense
                type="number"
                v-model="hotelBookingDeparture.metadata.no_of_triple_sharing_crews"
                prepend-inner-icon="mdi-account"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Total No. Of Nights"
            placeholder="Enter Total No. Of Nights"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            v-model="hotelBookingDeparture.metadata.no_of_nights"
            prepend-inner-icon="mdi-home"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Service Charges"
            placeholder="Enter Service Charges"
            :rules="amountRules"
            outlined
            dense
            type="number"
            v-model="hotelBookingDeparture.amount"
            prepend-inner-icon="mdi-currency-usd"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Supplier"
            placeholder="Select Supplier"
            outlined
            dense
            v-model="hotelBookingDeparture.card_id"
            prepend-inner-icon="mdi-account"
            :items="hotelBookingSuppliers"
            :rules="[rules.required]"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-select
            label="Tax"
            placeholder="Select Tax"
            outlined
            dense
            v-model="hotelBookingDeparture.metadata.tax"
            prepend-inner-icon="mdi-cash-fast"
            :items="taxes"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea
            label="Remarks"
            placeholder="Enter Remarks"
            outlined
            dense
            hide-details
            :rows="2"
            v-model="hotelBookingDeparture.remarks"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';
  import _find from 'lodash/find';

  export default {
    name: 's-services-opted-crew-change-service',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      servicesOpted: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        loading: false,
        form: false,
        isDisabled: false,
        rules,
        hotelBookingDeparture: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          bill_type: 'Hotel Booking Crew Sign Off',
          metadata: {
            no_of_nights: 0,
            singleSharing: false,
            doubleSharing: false,
            tripleSharing: false,
            no_of_single_sharing_crews: 0,
            no_of_double_sharing_crews: 0,
            no_of_triple_sharing_crews: 0,
            tax: ''
          },
          remarks: '',
        },
        hotelBookingSuppliers: [],
        taxes: ['0%', '9%']
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      initServiceOptedDetails() {
        const hotelBookingDeparture = _.find(this.servicesOpted, {bill_type: 'Hotel Booking Crew Sign Off'});
        if (hotelBookingDeparture?.card_id) {
          this.hotelBookingDeparture = hotelBookingDeparture;
          this.hotelBookingDeparture.amount = parseFloat(this.hotelBookingDeparture.amount).toFixed(2);
        }
      },
      populateSuppliers() {
        this.$api.service_catalogs.get_suppliers_list('Hotel Booking Crew Sign Off')
          .then((response) => {
            this.hotelBookingSuppliers = response.suppliers;
            this.hotelBookingDeparture.service_catalog_id = response.service_catalog_id;
            this.initServiceOptedDetails();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      saveDetails() {
        if(!this.submit()) return;
        this.loading = true;
        this.hotelBookingDeparture = Object.assign(this.hotelBookingDeparture, {
          operation_id: this.operation.id
        });

        Promise.all([
            this.$api.operation_billed_items.save_service(this.hotelBookingDeparture)
          ]).then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Hotel Booking Crew Sign Off service updated successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      }
    },
    computed: {
      amountRules() {
        if (this.isDraft || (this.operation && this.operation.is_draft)) {
          return [this.rules.required, this.rules.negative];
        } else {
          return [this.rules.negative];
        }
      }
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>