<template>
  <v-container fluid>
    <v-form ref="form" v-model="form">
      <v-row class="align-center">
        <v-col cols="6" class="py-0">
          Total Estimated Cost : <b>$ {{ totalEstimatedCost }}</b>
        </v-col>
        <v-col cols="6" class="py-0 d-flex justify-end">
          <v-btn
            v-if="$root.hasAccess('can_manage_fda')"
            :style="buttonStyle"
            outlined
            @click="approveService"
          >
            {{ buttonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../utilities/validator';
  import _ from 'lodash';

  export default {
    name: 's-fda',
    props: {
      operation: {
        type: Object,
        default: {},
      }
    },
    data() {
      return {
        form: false,
        rules,
        loading: false,
        search: '',
        services: [],
        amount: 0,
        items: [],
      };
    },
    computed: {
      totalEstimatedCost() {
        return this.services.reduce((total, service) => total + parseFloat(service.amount || 0), 0);
      },
      buttonText() {
        return this.operation.approved_by ? 'Approved' : 'Approve PDA';
      },
      buttonStyle() {
        return this.operation.approved_by
          ? { backgroundColor: '#4caf50', color: 'white' }
          : { backgroundColor: '#273978', color: 'white' };
      }
    },
    methods: {
      handleOperationInit() {
        this.loadAllServicesOpted();
      },
      loadAllServicesOpted() {
        this.loading = true;
        this.$api.operation_billed_items.index(this.operation.id)
          .then((response) => {
            this.services = response.suppliers;
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      },
      approveService() {
        this.$api.operations.getPDAApproved(this.operation.id)
          .then((response) => {
            this.operation.approved_by = response.operation?.approved_by;
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'PDA has been Approved Successfully.',
            });
            if (!this.operation.is_draft){
              this.$emit('nextStep', 5, this.operation);
            } else {
              this.$emit('dialogClose');
            }
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      }
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>