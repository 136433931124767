<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Arrival Duration"
            placeholder="Enter duration in hours"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            step="0.5"
            v-model="serviceOptFormArrival.metadata.duration"
            prepend-inner-icon="mdi-clock-in"
            @input="handleInputChange"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Tug Boat Arrival Service Charges"
            placeholder="Enter Tug Boat Arrival Service Charges"
            outlined
            dense
            type="number"
            v-model="serviceOptFormArrival.amount"
            prepend-inner-icon="mdi-currency-usd"
            :hint="arrivalHintText"
            :rules="amountRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Purpose"
            placeholder="Select purpose"
            outlined
            dense
            v-model="serviceOptFormArrival.metadata.purpose"
            prepend-inner-icon="mdi-anchor"
            :items="purposesItems"
            :rules="requiredRules"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Tugs In"
            placeholder="Enter Tugs In"
            outlined
            dense
            type="text"
            v-model="serviceOptFormArrival.metadata.tugs_in"
            prepend-inner-icon="mdi-login"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Escort Tugs"
            placeholder="Enter Escort Tugs"
            outlined
            dense
            type="text"
            v-model="serviceOptFormArrival.metadata.escort_tugs"
            prepend-inner-icon="mdi-looks"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Supplier"
            placeholder="Select arrival supplier"
            outlined
            dense
            v-model="serviceOptFormArrival.card_id"
            prepend-inner-icon="mdi-account"
            :items="suppliersList"
            :rules="[rules.required]"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-select
            label="Tax"
            placeholder="Select Tax"
            outlined
            dense
            v-model="serviceOptFormArrival.metadata.tax"
            prepend-inner-icon="mdi-cash-fast"
            :items="taxes"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea
            label="Remarks"
            placeholder="Enter Remarks"
            outlined
            dense
            hide-details
            :rows="2"
            v-model="serviceOptFormArrival.remarks"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn
            class="float-right"
            color="success"
            outlined
            :disabled="loading"
            :loading="loading"
            @click="saveDetails"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import rules from '../../../../utilities/validator';
import _ from 'lodash';

export default {
  name: 's-services-opted-tug-boat',
  props: {
    operation: {
      type: Object,
      default: {},
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
    servicesOpted: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      loading: false,
      form: false,
      isInitialized: false,
      rules,
      serviceOptFormArrival: {
        service_catalog_id: null,
        card_id: null,
        amount: 0,
        bill_type: 'Tug Boat Arrival',
        remarks: '',
        metadata: {
          duration: 0,
          purpose: '',
          tugs_in: '',
          escort_tugs: '',
          tax: ''
        },
      },
      arrival_calculated_amount: 0,
      suppliersList: [],
      purposesItems: ['Shipyard', 'Breakyard', 'On Tow', 'Standby'],
      taxes: ['0%', '9%']
    };
  },
  methods: {
    submit() {
      return this.$refs.form.validate();
    },
    handleOperationInit() {
      this.populateSuppliers();
    },
    initServiceOptedDetails() {
      const arrivalService = _.find(this.servicesOpted, { bill_type: 'Tug Boat Arrival' });
      if (arrivalService?.card_id) {
        this.serviceOptFormArrival = arrivalService;
        this.serviceOptFormArrival.amount = parseFloat(this.serviceOptFormArrival.amount).toFixed(2);
      }
      this.isInitialized = true;
    },
    populateSuppliers() {
      this.$api.service_catalogs
        .get_suppliers_list('Tug Boat Arrival')
        .then((response) => {
          this.suppliersList = response.suppliers;
          this.serviceOptFormArrival.service_catalog_id = response.service_catalog_id;
          this.initServiceOptedDetails();
        })
        .catch((err) => {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: err.message,
          });
        })
        .finally(() => this.initCalculation(true));
    },
    saveDetails() {
      if (!this.submit()) return;
      this.loading = true;
      this.serviceOptFormArrival = Object.assign(this.serviceOptFormArrival, {
        operation_id: this.operation.id,
      });

      this.$api.operation_billed_items
        .save_service(this.serviceOptFormArrival)
        .then(() => {
          this.$store.dispatch('setAlert', {
            status: 'success',
            show: true,
            message: 'Tug Boat service updated successfully',
          });
        })
        .catch((err) => {
          this.$store.dispatch('setAlert', {
            status: 'error',
            show: true,
            message: err.message,
          });
        })
        .finally(() => (this.loading = false));
    },
    initCalculation(isInitialization = false) {
      if (this.serviceOptFormArrival.metadata.duration) {
        const arrivalAmount = this.calculateAmount(
          this.serviceOptFormArrival.metadata.duration,
          this.operation.gross_tonnage
        );
        this.arrival_calculated_amount = parseFloat(arrivalAmount).toFixed(2);

        if (!isInitialization) {
          this.serviceOptFormArrival.amount = this.arrival_calculated_amount;
        }
      }
    },
    handleInputChange() {
      if (this.isInitialized) {
        this.initCalculation(); // Only recalculate after initialization
      }
    },
    calculateAmount(duration, grossTonnage) {
      duration = parseFloat(duration);
      const priceMapping = [
        [0, 2000, 330, 165],
        [2000, 5000, 385, 192.5],
        [5000, 10000, 420, 210],
        [10000, 15000, 440, 220],
        [15000, 20000, 638, 319],
        [20000, 30000, 680, 340],
        [30000, 60000, 840, 420],
        [60000, 100000, 1100, 550],
      ];
      let amount = 0;
      priceMapping.forEach((priceMap) => {
        if (priceMap[0] < grossTonnage && grossTonnage <= priceMap[1]) {
          if (duration > 1) {
            amount = priceMap[2] + priceMap[3] * ((duration * 2) - 2);
          } else {
            amount = priceMap[2] * duration;
          }
        }
      });
      if (grossTonnage > 100000) {
        if (duration > 1) {
          amount = 1260 + 630 * ((duration * 2) - 2);
        } else {
          amount = 1260 * duration;
        }
      }
      return amount;
    },
  },
  computed: {
    requiredRules() {
      if (this.isDraft || (this.operation && this.operation.is_draft)) {
        return [];
      } else {
        return [this.rules.required];
      }
    },
    amountRules() {
      if (this.isDraft || (this.operation && this.operation.is_draft)) {
        return [this.rules.required, this.rules.negative];
      } else {
        return [this.rules.negative];
      }
    },
    arrivalHintText() {
      if (Number(this.serviceOptFormArrival.amount) != this.arrival_calculated_amount) {
        return `Tug Boat arrival service charges differ from calculated amount $${this.arrival_calculated_amount}`;
      }
    },
  },
  watch: {
    'serviceOptFormArrival.metadata.duration'() {
      this.initCalculation();
    },
  },
};
</script>