<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
        <v-select
          label="Filter By Vessel"
          placeholder="Select a vessel"
          outlined
          dense
          v-model="vesselName"
          prepend-inner-icon="mdi-filter"
          :items="vesselNames"
          item-text="name"
          item-value="id"
          @change="applyFilter({vessel_id: $event})"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
        <v-select
          label="Filter By Date Range Type"
          placeholder="Select a date range type"
          outlined
          dense
          v-model="dateRangeType"
          prepend-inner-icon="mdi-calendar"
          :items="dateRangeTypes"
          item-text="name"
          item-value="id"
          @change="applyFilter({date_range_type: $event})"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="dates"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Select date range"
              prepend-inner-icon="mdi-calendar"
              readonly
              outlined
              hide-details
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            id="dateRangePicker"
            v-model="dates"
            range
            @change="applyFilter({date_range: dateRangeText})"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(dates)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <template v-slot:item.fda="{ item }">
          <v-btn
            small
            color="primary"
            @click="navigateOperation('FDA', item)"
          >
            Open Job
          </v-btn>
        </template>
        <template v-slot:item.pda="{ item }">
          <v-btn
            small
            color="primary"
            @click="navigateOperation('PDA', item)"
          >
            Create PDA
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import moment from 'moment';
  import moment_timezone from 'moment-timezone'

  export default {
    name: 's-vessel-voyages',
    data() {
      return {
        search: '',
        loading: false,
        headers: [
          {text: 'Id', value: 'id', align: 'start'},
          {text: 'Name', value: 'vessel_name', align: 'start'},
          {text: 'Location From', value: 'location_from', align: 'center'},
          {text: 'Location To', value: 'location_to', align: 'center'},
          {text: 'Estimated Arrival Time', value: 'formatted_dueto_arrive_time', align: 'center'},
          {text: 'Actual Arrival Time', value: 'formatted_arrived_time', align: 'center'},
          {text: 'Estimated Departure Time', value: 'formatted_dueto_depart', align: 'center'},
          {text: 'Actual Departure Time', value: 'formatted_departed_time', align: 'center'},
          {text: 'Open Job', value: 'fda', align: 'center'},
          {text: 'Job PDA', value: 'pda', align: 'center'},
        ],
        items: [],
        vesselName: 'All',
        vesselNames: [],
        vessels: [],
        customers: [],
        card_id: '',
        dates: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        modal: false,
        filters: {
          date_range_type: 'dueto_arrive_time',
          date_range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')].join(' ~ '),
          vessel_id: 'All'
        },
        dateRangeType: 'dueto_arrive_time',
        dateRangeTypes: [
          {id: 'dueto_arrive_time', name: 'Estimated Arrival Time'},
          {id: 'arrived_time', name: 'Actual Arrival Time'},
          {id: 'dueto_depart', name: 'Estimated Departure Time'},
          {id: 'departed_time', name: 'Actual Departure Time'},
        ]
      };
    },
    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
    methods: {
      navigateOperation(jobType, voyage) {
        const vessel = _.find(this.vessels, {id: voyage.vessel_id});
        if (!(vessel && vessel.vessel_owner_id)) return;
        const customer = _.find(this.customers, {id: vessel.vessel_owner_id});
        if (!(customer && customer.id)) return;

        this.card_id = customer.id;
        this.$router.push({
          name: 'port calls',
          query: {
            createMode: jobType,
            vesselId: voyage.vessel_id,
            customerId: this.card_id,
            eta: voyage.arrived_time || voyage.dueto_arrive_time,
            etd: voyage.departed_time || voyage.dueto_depart,
          },
        });
      },
      applyFilter(filterObj) {
        this.filters = Object.assign(this.filters, filterObj);
        this.getVoyages(this.filters);
      },
      getVoyages() {
        this.loading = true;
        this.$api.vessel_voyages.index(this.filters)
          .then((response) => {
            const columnsToFormat = [
              'dueto_arrive_time',
              'arrived_time',
              'dueto_depart',
              'departed_time'
            ];
            this.items = response.vesselVoyages.map(obj => {
              Object.keys(obj).forEach(key => {
                if (obj[key] && columnsToFormat.includes(key)) {
                  obj['formatted_' + key] = moment(obj[key]).tz('Singapore').format('l LT');
                }
              });
              return obj;
            });
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      populateCards() {
        this.$api.cards.index()
          .then((response) => {
            this.customers = response.cards;
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      getVessels() {
        this.loading = true;
        this.$api.vessels.index()
          .then((response) => {
            this.vesselNames = [{id: 'All', name: 'All'}, ...response.vessels.map(x => ({id: x.id, name: x.name}))];
            this.vessels = response.vessels;
            this.loading = false;
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
    },
    created() {
      this.getVoyages();
      this.getVessels();
      this.populateCards();
    },
  };
</script>
<style>
  #dateRangePicker {
    .v-btn--active {
      background-color: #273978 !important;
      border-color: #273978 !important;
    }
  }
</style>