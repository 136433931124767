<template>
  <div class="text-center">
    <v-dialog v-model="showForm" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{action}}
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="form" v-model="form" @submit.prevent="submit">
          <v-card-text class="pb-0">
            <v-text-field
              label="Vessel Name"
              placeholder="Enter Vessel Name"
              :rules="[rules.required]"
              outlined
              dense
              v-model="vesselForm.name"
              prepend-inner-icon="mdi-ferry"
              clearable
              :disabled="!!vesselForm.id"
            ></v-text-field>
            <v-text-field
              label="Vessel Type"
              placeholder="Enter Vessel Type"
              :rules="[rules.required]"
              outlined
              dense
              v-model="vesselForm.vessel_type"
              prepend-inner-icon="mdi-apple-safari"
              clearable
              :disabled="!!vesselForm.id"
            ></v-text-field>
            <v-text-field
              label="IMO Number"
              placeholder="Enter IMO Number"
              :rules="[rules.required]"
              outlined
              dense
              v-model="vesselForm.imo_number"
              prepend-inner-icon="mdi-numeric"
              clearable
              :disabled="!!vesselForm.id"
            ></v-text-field>
            <v-text-field
              label="Call Sign"
              placeholder="Enter Call Sign"
              :rules="[rules.required]"
              outlined
              dense
              v-model="vesselForm.call_sign"
              prepend-inner-icon="mdi-forward"
              clearable
              :disabled="!!vesselForm.id"
            ></v-text-field>
            <v-text-field
              label="Flag"
              placeholder="Enter Flag"
              :rules="[rules.required]"
              outlined
              dense
              v-model="vesselForm.flag"
              prepend-inner-icon="mdi-flag"
              clearable
              :disabled="!!vesselForm.id"
            ></v-text-field>
            <v-text-field
              label="Parent Company"
              placeholder="Enter Parent Company"
              outlined
              dense
              v-model="vesselForm.parent_company"
              prepend-inner-icon="mdi-office-building"
              clearable
            ></v-text-field>
            <v-text-field
              label="Registered Ownership"
              placeholder="Enter Registered Ownership"
              :rules="[rules.required]"
              outlined
              dense
              v-model="vesselForm.registered_ownership"
              prepend-inner-icon="mdi-comment-account"
              clearable
              :disabled="!!vesselForm.id"
            ></v-text-field>
            <v-text-field
              label="Email"
              placeholder="Enter Email Address"
              :rules="[rules.email]"
              outlined
              dense
              v-model="vesselForm.email"
              prepend-inner-icon="mdi-email"
              clearable
            ></v-text-field>
            <v-text-field
              label="Mobile"
              placeholder="Enter Mobile Number"
              :rules="[rules.mobile]"
              outlined
              dense
              v-model="vesselForm.vessel_contact"
              prepend-inner-icon="mdi-phone"
              clearable
            ></v-text-field>
            <v-select
              label="Vessel Owner"
              placeholder="Select vessel owner"
              outlined
              multiple
              dense
              :items="filteredOwners"
              :rules="[rules.required]"
              v-model="vesselForm.owners"
              prepend-inner-icon="mdi-account"
              item-text="name"
              item-value="id"
            >
              <template v-slot:prepend-item>
                <v-text-field
                  v-model="searchQuery"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Search..."
                  clearable
                  @input="filterOwners"
                ></v-text-field>
              </template>
            </v-select>
            <v-text-field
              label="Gross Tonnage"
              placeholder="Enter Gross Tonnage"
              :rules="[rules.required]"
              outlined
              dense
              v-model="vesselForm.gross_tonnage"
              prepend-inner-icon="mdi-weight"
              clearable
              :disabled="!!vesselForm.id"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn color="primary" outlined @click="formSubmit()" 
              :disabled="loading" :loading="loading">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import rules from '../../../utilities/validator';

  export default {
    name: 's-vessel-manual-form',
    props: {
      action: {
        type: String,
        required: true,
      },
      owners: Array,
      showForm: {
        type: Boolean,
        default: false,
      },
      vessel: {
        type: Object,
        default: {},
      },
    },
    data() {
      let vesselInfo = {
        name: '',
        vessel_type: '',
        call_sign: '',
        imo_number: '',
        flag: '',
        registered_ownership: '',
        card_id: '',
        gross_tonnage: '',
        email: '',
        vessel_contact: '',
        parent_company: ''
      };

      return {
        rules,
        form: false,
        loading: false,
        searchQuery: '',
        filteredOwners: this.owners,
        vesselForm: Object.assign({}, vesselInfo),
      };
    },
    methods: {
      filterOwners() {
        if (!this.searchQuery) {
          this.filteredOwners = this.owners;
        } else {
          this.filteredOwners = this.owners.filter(owner =>
            owner.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        }
      },
      updateVessel() {
        this.loading = true;
        this.$api.vessels.update(this.vesselForm)
          .then((response) => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Vessel updated successfully',
            });
            this.$emit('formSubmitted');
          }).catch((err) => {0
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      },
      createVessel() {
        this.loading = true;
        this.$api.vessels.create(this.vesselForm)
          .then((response) => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Vessel created successfully',
            });
            this.$emit('formSubmitted');
          }).catch((err) => {0
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      },
      formSubmit() {
        if (this.vesselForm.id) {
          this.updateVessel();
        } else {
          this.createVessel();
        }
      },
    },
    watch: {
      vessel(value) {
        if (value && value.id) {
          this.vesselForm = value;
        }
      }
    },
    created() {
      if (this.vessel && this.vessel.id) {
        this.vesselForm = this.vessel;
      }
    }
  };
</script>