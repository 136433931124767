<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-switch
            dense
            hide-details
            v-model="serviceOptFormDeparture.metadata.rush_request"
            label="Cancellation / Amendment?"
            class="mt-1"
            @change="initCalculation"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-datetime-picker
            ref="departurePilotOnboard"
            label="Pilot Onboard Time"
            v-model="serviceOptFormDeparture.metadata.pilot_onboard_time"
            :textFieldProps="{dense: true, readonly: true, 'prepend-inner-icon': 'mdi-calendar', outlined: true, rules: requiredRules, id: 'departurePilotOnboardField'}"
            @input="updateDepartureDuration"
          >
            <template v-slot:dateIcon>
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template v-slot:timeIcon>
              <v-icon>mdi-calendar-clock</v-icon>
            </template>
          </v-datetime-picker>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-datetime-picker
            ref="departurePilotDisembark"
            label="Pilot Disembark Time"
            v-model="serviceOptFormDeparture.metadata.pilot_disembark_time"
            :textFieldProps="{dense: true, readonly: true, 'prepend-inner-icon': 'mdi-calendar', outlined: true, rules: requiredRules, id: 'departurePilotDisembarkField'}"
            @input="updateDepartureDuration"
          >
            <template v-slot:dateIcon>
              <v-icon>mdi-calendar</v-icon>
            </template>
            <template v-slot:timeIcon>
              <v-icon>mdi-calendar-clock</v-icon>
            </template>
          </v-datetime-picker>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-text-field
            label="Departure Duration(In Hours)"
            placeholder="Enter duration in hours"
            outlined
            dense
            type="number"
            step="0.5"
            v-model="serviceOptFormDeparture.metadata.duration"
            prepend-inner-icon="mdi-clock-out"
            @input="initCalculation"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Pilot Departure Service Charges"
            placeholder="Enter Pilot Departure Service Charges"
            outlined
            dense
            type="number"
            v-model="serviceOptFormDeparture.amount"
            prepend-inner-icon="mdi-currency-usd"
            :hint="departureHintText"
            :rules="amountRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-select
            label="Supplier"
            placeholder="Select Departure supplier"
            outlined
            dense
            v-model="serviceOptFormDeparture.card_id"
            prepend-inner-icon="mdi-account"
            :items="suppliersList"
            :rules="[rules.required]"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-select
            label="Tax"
            placeholder="Select Tax"
            outlined
            dense
            v-model="serviceOptFormDeparture.metadata.tax"
            prepend-inner-icon="mdi-cash-fast"
            :items="taxes"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea
            label="Remarks"
            placeholder="Enter Remarks"
            outlined
            dense
            hide-details
            :rows="2"
            v-model="serviceOptFormDeparture.remarks"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';
  import _ from 'lodash';

  export default {
    name: 's-services-opted-pilot-service',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      servicesOpted: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        loading: false,
        form: false,
        rules,
        serviceOptFormDeparture: {
          service_catalog_id: null,
          card_id: null,
          bill_type: 'Pilot Service Outward',
          amount: 0,
          remarks: '',
          metadata: {
            pilot_onboard_time: null,
            pilot_disembark_time: null,
            duration: 0,
            rush_request: false,
            tax: ''
          }
        },
        departure_calculated_amount: 0,
        suppliersList: [],
        portAgeItems: [1,2,3,4,5],
        anchorageItems: [1,2,3,4,5],
        taxes: ['0%', '9%']
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      initServiceOptedDetails() {
        const departureService = _.find(this.servicesOpted, {bill_type: 'Pilot Service Outward'});
        if (departureService?.card_id) {
          this.serviceOptFormDeparture = departureService;
          this.serviceOptFormDeparture.amount = parseFloat(this.serviceOptFormDeparture.amount).toFixed(2);
          this.initDepartureDateTime();
        }
      },
      populateSuppliers() {
        Promise.all([
          this.$api.service_catalogs.get_suppliers_list('Pilot Service Outward')
            .then((response) => {
              this.suppliersList = response.suppliers;
              this.serviceOptFormDeparture.service_catalog_id = response.service_catalog_id;
              this.initServiceOptedDetails();
            })
          ]).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => this.initCalculation());
      },
      saveDetails() {
        if(!this.submit()) return;
        this.loading = true;
        this.serviceOptFormDeparture = Object.assign(this.serviceOptFormDeparture, {
          operation_id: this.operation.id
        });

        Promise.all([
            this.$api.operation_billed_items.save_service(this.serviceOptFormDeparture)
          ]).then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Pilot service updated successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      },
      initCalculation () {
        if (this.serviceOptFormDeparture.metadata.duration) {
          const departureAmount = this.calculateAmount(this.serviceOptFormDeparture.metadata.duration, this.operation.gross_tonnage, this.serviceOptFormDeparture.metadata.rush_request);
          this.departure_calculated_amount = parseFloat(departureAmount).toFixed(2);
        }
      },
      calculateAmount(duration, grossTonnage, flag) {
        const priceMapping = [
          [0, 6000, 216.50, 108.25],
          [6000, 12000, 240, 120],
          [12000, 20000, 264.50, 132.25],
          [20000, 30000, 300.50, 150.25],
          [30000, 40000, 336, 168],
          [40000, 50000, 372, 186],
          [50000, 60000, 408, 204]
        ];
        let amount = 0;
        priceMapping.forEach(priceMap => {
          if (priceMap[0] < grossTonnage && grossTonnage <= priceMap[1]) {
            if (duration > 1) {
              amount = priceMap[2] + (priceMap[3] * ((duration * 2) - 2));  
            } else {
              amount = priceMap[2] * duration
            }
          }
        });
        if (grossTonnage > 60000) {
          if (duration > 1) {
            amount = 444 + (222 * ((duration * 2) - 2));  
          } else {
            amount = 444 * duration;
          }
        }
        if (flag) {
          amount = amount * 1.5;
        }
        return amount;
      },
      updateDepartureDuration() {
        if (this.serviceOptFormDeparture.metadata.pilot_disembark_time && this.serviceOptFormDeparture.metadata.pilot_onboard_time) {
          const diffMs = this.serviceOptFormDeparture.metadata.pilot_disembark_time - this.serviceOptFormDeparture.metadata.pilot_onboard_time;
          const hours = (Math.floor(diffMs / 86400000) * 24) + Math.floor((diffMs % 86400000) / 3600000);
          const minutesPercent = Math.round((((diffMs % 86400000) % 3600000) / 60000) / 60 * 100);
          this.serviceOptFormDeparture.metadata.duration = parseFloat(`${hours}.${minutesPercent}`);
          this.initCalculation();
        }
      },
      preserveForm(value, key, arrival) {
        const [Day, Month, Year] = new Date(value).toLocaleDateString().split('/');
        const [Hr, Min, Sec] = new Date(value).toLocaleTimeString().split(':');

        if (arrival) {
          this.$set(this.serviceOptFormDeparture.metadata, key, `${Year}-${Month}-${Day} ${Hr}:${Min}`);
        }
      },
      initDepartureDateTime() {
        if (this.serviceOptFormDeparture.metadata.pilot_disembark_time) {
          this.preserveForm(this.serviceOptFormDeparture.metadata.pilot_disembark_time, 'pilot_disembark_time', true);
        }
        if (this.serviceOptFormDeparture.metadata.pilot_onboard_time) {
          this.preserveForm(this.serviceOptFormDeparture.metadata.pilot_onboard_time, 'pilot_onboard_time', true);
        }
      },
    },
    computed: {
      requiredRules() {
        if (this.isDraft || (this.operation && this.operation.is_draft)) {
          return [];
        } else {
          return [this.rules.required];
        }
      },
      amountRules() {
        if (this.isDraft || (this.operation && this.operation.is_draft)) {
          return [this.rules.required, this.rules.negative];
        } else {
          return [this.rules.negative];
        }
      },
      departureHintText() {
        if (Number(this.serviceOptFormDeparture.amount) != this.departure_calculated_amount) {
          return `Pilot departure service charges differs from calculated amount (${this.departure_calculated_amount})`;
        } else {
          return "";
        }
      }
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      }
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>