<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-switch
            dense
            hide-details
            v-model="serviceOptForm.metadata.arrange_by_owner"
            label="Arrange By Principal?"
            class="mt-0 pt-0"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!serviceOptForm.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Supplies Amount"
            placeholder="Enter Supplies Amount"
            :rules="amountRules"
            outlined
            dense
            v-model="serviceOptForm.amount"
            type="number"
            prepend-inner-icon="mdi-currency-usd"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!serviceOptForm.metadata.arrange_by_owner">
          <v-select
            label="Supplier"
            placeholder="Select a supplier"
            outlined
            dense
            v-model="serviceOptForm.card_id"
            prepend-inner-icon="mdi-account"
            :items="suppliers"
            :rules="supplierRules"
            :disabled="isDisabled"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-select
            label="Tax"
            placeholder="Select Tax"
            outlined
            dense
            v-model="serviceOptForm.metadata.tax"
            prepend-inner-icon="mdi-cash-fast"
            :items="taxes"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea
            label="Remarks"
            placeholder="Enter Remarks"
            outlined
            dense
            hide-details
            :rows="2"
            v-model="serviceOptForm.remarks"
          ></v-textarea>
        </v-col>
        <v-col cols="12" v-show="!serviceOptForm.metadata.arrange_by_owner">
          <v-data-table
            :headers="headers"
            :items="provisions"
            hide-default-footer
            id="provisionsTable"
          >
            <template v-slot:body="{ items }">
              <tr v-for="(item, index) in items" :key="index">
                <td>{{index + 1}}</td>
                <td>{{item.name}}</td>
                <td>{{item.unit_price}}</td>
                <td>{{item.quantity}}</td>
                <td>{{item.unit_price * item.quantity}}</td>
                <td>
                  <v-btn color="red" icon @click="removeProvisionEntry(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card>
            <v-card-title>
              Add New Provision Item
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4" md="3" lg="3" class="pb-0">
                  <v-text-field
                    label="Provision Name"
                    placeholder="Enter Provision Name"
                    outlined
                    dense
                    v-model="provisionObj.name"
                    prepend-inner-icon="mdi-format-align-center"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="3" lg="3" class="pb-0">
                  <v-text-field
                    label="Unit Price"
                    placeholder="Enter Unit Price"
                    :rules="[rules.negative]"
                    outlined
                    dense
                    type="number"
                    v-model="provisionObj.unit_price"
                    prepend-inner-icon="mdi-currency-usd"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="3" lg="3" class="pb-0">
                  <v-text-field
                    label="Quantity"
                    placeholder="Enter Quantity"
                    :rules="[rules.negative]"
                    outlined
                    dense
                    type="number"
                    v-model="provisionObj.quantity"
                    prepend-inner-icon="mdi-checkbox-multiple-blank-outline"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="3" lg="3" class="pb-0">
                  <v-btn color="primary" @click="addProvision">
                    Add
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';
  import _find from 'lodash/find';

  export default {
    name: 's-services-opted-supplies-service',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      servicesOpted: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        loading: false,
        form: false,
        isDisabled: false,
        rules,
        serviceOptForm: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          bill_type: "Supplies",
          remarks: '',
          metadata: {
            arrange_by_owner: false,
            provisions_list: [],
            tax: ''
          }
        },
        suppliers: [],
        sinodaSupplier: [],
        taxes: ['0%', '9%'],
        provisionObj: {
          name: '',
          unit_price: 0,
          quantity: 0,
        },
        provisions: [],
        headers: [
          {text: "S.No.",value: "id"},
          {text: "Provision Name",value: "name"},
          {text: "Unit Price",value: "unit_price"},
          {text: "Quantity",value: "quantity"},
          {text: "Amount",value: "amount"},
          {text: "Remove",value: "delete"},
        ]
      };
    },
    methods: {
      removeProvisionEntry(index) {
        this.loading = true;
        this.$api.operation_billed_items.remove_provision_entry(this.serviceOptForm.id, { index })
          .then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Service entry removed successfully',
            });
            this.provisions.splice(index, 1);
          })
          .catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          })
          .finally(() => {
            this.loading = false;
          });
      },
      submit() {
        return this.$refs.form.validate();
      },
      addProvision() {
        this.provisions.push(this.provisionObj);
        this.provisionObj = {
          name: '',
          unit_price: 0,
          quantity: 0,
        };
        this.provisionsTotal();
      },
       provisionsTotal() {
        return this.serviceOptForm.amount = this.provisions.map(x => x.unit_price * x.quantity).reduce((a, b) => a + b, 0)
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      initServiceOptedDetails() {
        const service = _.find(this.servicesOpted, {bill_type: 'Supplies'});
        if (service?.card_id) {
          this.serviceOptForm = service;
          this.serviceOptForm.amount = parseFloat(this.serviceOptForm.amount).toFixed(2);
          this.provisions = JSON.parse(this.serviceOptForm.metadata.provisions_list) || [];
        }
      },
      populateSuppliers() {
        this.$api.service_catalogs.get_suppliers_list('Supplies')
          .then((response) => {
            this.suppliers = response.suppliers;
            this.sinodaSupplier = _find(this.suppliers, { name: 'Sinoda' });
            if (this.sinodaSupplier) {
              this.serviceOptForm.card_id = this.sinodaSupplier.id;
              this.isDisabled = true;
            }
            this.serviceOptForm.service_catalog_id = response.service_catalog_id;
            this.initServiceOptedDetails();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      saveDetails() {
        if(!this.submit()) return;
        this.loading = true;
        this.serviceOptForm = Object.assign(this.serviceOptForm, {
          operation_id: this.operation.id
        });
        // this.serviceOptForm.amount = this.provisionsTotal;
        this.serviceOptForm.metadata.provisions_list = JSON.stringify(this.provisions)
        this.$api.operation_billed_items.save_service(this.serviceOptForm)
          .then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Supplies service updated successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      },
    },
    computed: {
      supplierRules() {
        if (this.serviceOptForm.metadata.arrange_by_owner) {
          return [];
        } else {
          return [this.rules.required];
        }
      },
      amountRules() {
        if (this.serviceOptForm.metadata.arrange_by_owner) {
          return [];
        } else {
          return [this.rules.required,this.rules.negative];
        }
      }
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>

<style scoped>
  #provisionsTable td {
    padding: 0 16px;
  }
</style>