<template>
  <v-container fluid class="text-left">
    <div v-for="(service, index) in servicesOpted" :key="index" class="py-4">
      <div class="subtitle-1 font-weight-bold py-4">
        {{ service.bill_type }}
      </div>
      <v-row class="text-left">
        <v-col cols="12" sm="6" class="d-flex">
          <v-row class="job-row__border ma-1 align-center">
            <v-col cols="6" class="primary--text subtitle-2 font-weight-bold py-0">
              Supplier
            </v-col>
            <v-col cols="6" class="subtitle-2 py-0">
              {{service.supplier}}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-row class="job-row__border ma-1 align-center">
            <v-col cols="6" class="primary--text subtitle-2 font-weight-bold py-0">
              Amount
            </v-col>
            <v-col cols="6" class="subtitle-2 py-0">
              S$ {{ parseFloat(service.amount || 0).toFixed(2) }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex" 
          v-for="(item, index) in Object.keys(service.metadata || {})"
          :key="index" v-if="hasAccessFields(item)"
        >
          <v-row class="job-row__border ma-1 align-center">
            <v-col cols="6" class="text-capitalize primary--text subtitle-2 font-weight-bold py-0">
              {{item.split('_').join(' ')}}
            </v-col>
            <v-col cols="6" class="subtitle-2 py-0">
              <span v-if="typeof(service.metadata[item]) == 'boolean'">
                {{service.metadata[item] ? 'Yes' : 'No'}}
              </span>
              <span v-else>
                {{service.metadata[item]}}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex">
          <v-row class="job-row__border ma-1 align-center">
            <v-col cols="6" class="primary--text subtitle-2 font-weight-bold py-0">
              Remarks
            </v-col>
            <v-col cols="6" class="subtitle-2 py-0">
              {{service.remarks}}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 's-show-services-opted',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      vesselInfo: {
        type: Object,
        default: {},
      },
    },
    data () {
      return {
        servicesOpted: [],
      };
    },
    methods: {
      hasAccessFields(item) {
        const priceFields = ['percentage', 'price_per_member', 'price_per_application', 'price_per_metric_ton', 'distance_charges', 'price_per_hour', 'waiting_time_charges'];

        if (priceFields.includes(item)) {
          return this.$root.hasAccess('can_manage_operation_amount_details');
        }
        return true;
      },
      handleOperationInit() {
        this.getBilledItems();
      },
      getBilledItems() {
        this.$api.operation_billed_items.billed_items_checklist(this.operation.id)
          .then((response) => {
            this.servicesOpted = response.checklist;
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      },
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>

<style scoped>
  .job-row__border {
    border-bottom: 1px solid lightgray;
  }
</style>