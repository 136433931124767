<template>
  <v-container fluid>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab bottom right fixed color="primary"
          v-bind="attrs" v-on="on" @click="printReport">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </template>
      <span>Download PDF</span>
    </v-tooltip>
    <div class="d-flex justify-center">
      <div class="text-center content-placeholder">
        <img
          class="d-inline-block logo-w"
          :src="SinodaLogo"
        />
        <v-card flat>
          <v-row class="py-4">
            <v-col cols="12" class="text-center">
              <div class="text-h6 font-weight-bold section-title">
                Vessel Report
              </div>
            </v-col>
          </v-row>
          <ShowJobDetails :operation="operation" />

          <v-row class="py-4 mt-10" v-if="operation.vessel_information">
            <v-col cols="12" class="text-center">
              <div class="text-h6 font-weight-bold section-title">
                Vessel Information
              </div>
            </v-col>
          </v-row>
          <ShowVesselArrivalInformation v-if="operation.vessel_information" :operation="operation" :vessel-info="vesselInfo" />

          <v-row class="py-4">
            <v-col cols="12" class="text-center">
              <div class="text-h6 font-weight-bold section-title">
                Services Opted
              </div>
            </v-col>
          </v-row>
          <ShowServicesOpted :operation="operation" :vessel-info="vesselInfo" />
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
  import ShowJobDetails from './blocks/ShowJobDetails';
  import ShowVesselArrivalInformation from './blocks/ShowVesselArrivalInformation';
  import ShowServicesOpted from './blocks/ShowServicesOpted';
  import axios from 'axios';
  import SinodaLogo from 'images/SinodaLetterHead.png';

  export default {
    name: 's-operation-show',
    components: {
      ShowJobDetails,
      ShowVesselArrivalInformation,
      ShowServicesOpted,
    },
    data() {
      let vesselInfoObj = {
        captain_mail_id: '',
        coming_from: '',
        going_to: '',
        last_port: '',
        next_port: '',
        fwd: '',
        aft: '',
        ht: '',
        freeboard: '',
        cargo_type: '',
        qty_volume: '',
        vessel_contact: '',
        bunker: {
          grade: '',
          oil_intake_rate: '',
          mgo: '',
          gas_intake_rate: '',
          two_grade_together: '',
          freshwater_quantity: '',
          freshwater_intake_rate: '',
        },
      };

      return {
        imageData: '',
        loading: false,
        SinodaLogo,
        tmpForm: Object.assign({}, vesselInfoObj),
        vesselInfo: Object.assign({}, vesselInfoObj),
        operation: {},
        tab: null,
        items: [
          {label: 'Job Details', icon: 'mdi-anchor', component: 'show-job-details'},
          {label: 'Vessel Information', icon: 'mdi-ferry', component: 'show-vessel-arrival-information'},
          {label: 'Services Opted', icon: 'mdi-gas-station', component: 'show-services-opted'},
        ],
      };
    },
    methods: {
      printReport() {
        window.print();
      },
      getCaptainDetails(operationId) {
        this.$api.operation_captain_details.show(operationId)
          .then(response => {
            if (response.operation_captain_detail) {
              this.vesselInfo = response.operation_captain_detail;
            } else {
              this.vesselInfo = Object.assign({}, this.tmpForm);
            }
          }).catch(err => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      getOperations() {
        this.loading = true;
        this.$api.operations.index({ operation_id: this.$route.params.id })
          .then((response) => {
            this.operation = response.operations[0];
            this.loading = false;
            this.$root.pageTitle = `Operation #${this.operation.id}`;
            this.getCaptainDetails(this.operation.id);
          }).catch((err) => {
            this.loading = false;
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      }
    },
    beforeDestroy() {
      this.$root.pageTitle = '';
    },
    created() {
      this.$root.pageTitle = 'Operation';
      this.getOperations();
    },
  };
</script>

<style scoped>
  .content-placeholder {
    max-width: 837px;
    border: 1px solid #ffffff;
    margin: 48px;
    box-decoration-break: clone;
  }
  @media (max-width: 600px) {
    .content-placeholder {
      max-width: 837px;
      border: 1px solid #ffffff;
      margin: 4px;
      box-decoration-break: clone;
    }
    .logo-w {
      width: 75%;
    }
  }
  .logo-w {
    width: 80%;
  }
  .section-title {
    background-color: #e6e6e6 !important;
    padding: 4px;
  }
</style>