<template>
  <v-container fluid>
    <v-form ref="form" v-model="form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" class="pb-0">
          <span class="text-h6">Bunker Service</span>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0">
          <v-switch
            dense
            hide-details
            v-model="bunkerServiceOptForm.metadata.arrange_by_owner"
            label="Arrange By Principal?"
            class="mt-0 pt-0"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!bunkerServiceOptForm.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Price Per Metric Ton"
            placeholder="Enter Price Per Metric Ton"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            v-model="bunkerServiceOptForm.metadata.price_per_metric_ton"
            prepend-inner-icon="mdi-currency-usd"
            @input="calculateBunkerPrice"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!bunkerServiceOptForm.metadata.arrange_by_owner">
          <v-text-field
            label="No. of Metric Tons"
            placeholder="Enter No. of Metric Tons"
            :rules="[rules.negative]"
            outlined
            dense
            type="number"
            v-model="bunkerServiceOptForm.metadata.no_of_metric_tons"
            prepend-inner-icon="mdi-weight"
            @input="calculateBunkerPrice"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!bunkerServiceOptForm.metadata.arrange_by_owner" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-text-field
            label="Total Price"
            placeholder="Enter Total Price"
            outlined
            dense
            type="number"
            v-model="bunkerServiceOptForm.amount"
            prepend-inner-icon="mdi-currency-usd"
            :hint="hintText"
            :rules="amountRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!bunkerServiceOptForm.metadata.arrange_by_owner">
          <v-select
            label="Grade"
            placeholder="Select a grade"
            outlined
            dense
            v-model="bunkerServiceOptForm.metadata.grade"
            prepend-inner-icon="mdi-blur"
            :items="grades"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!bunkerServiceOptForm.metadata.arrange_by_owner">
          <v-select
            label="Bunker Supplier"
            placeholder="Select Bunker supplier"
            outlined
            dense
            v-model="bunkerServiceOptForm.card_id"
            prepend-inner-icon="mdi-account"
            :items="bunkerSuppliers"
            :rules="supplierRules"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-if="$root.hasAccess('can_manage_operation_amount_details')">
          <v-select
            label="Tax"
            placeholder="Select Tax"
            outlined
            dense
            v-model="bunkerServiceOptForm.metadata.tax"
            prepend-inner-icon="mdi-cash-fast"
            :items="taxes"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-textarea
            label="Remarks"
            placeholder="Enter Remarks"
            outlined
            dense
            hide-details
            :rows="2"
            v-model="bunkerServiceOptForm.remarks"
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="4" class="pb-0" v-show="!bunkerServiceOptForm.metadata.arrange_by_owner">
          <v-switch
            dense
            hide-details
            v-model="bunkerServiceOptForm.metadata.two_grade_together"
            label="2 Grades Together?"
            class="mt-0 pt-0"
          ></v-switch>
        </v-col>
        <v-col cols="12" class="pb-0">
          <v-btn class="float-right" color="success" outlined :disabled="loading" :loading="loading"
            @click="saveDetails">Save</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import rules from '../../../../utilities/validator';

  export default {
    name: 's-services-opted-bunker-service',
    props: {
      operation: {
        type: Object,
        default: {},
      },
      isDraft: {
        type: Boolean,
        default: false,
      },
      servicesOpted: {
        type: Array,
        default: []
      },
    },
    data() {
      return {
        loading: false,
        form: false,
        rules,
        grades: [
          'High',
          'Intermediate',
          'Medium',
          'Low',
          'Very Low Sulphur',
          'Not Indicated'
        ],
        bunkerServiceOptForm: {
          service_catalog_id: null,
          card_id: null,
          amount: 0,
          bill_type: "Bunker Service",
          remarks: '',
          metadata: {
            arrange_by_owner: false,
            price_per_metric_ton: 0,
            no_of_metric_tons: 0,
            grade: '',
            two_grade_together: false,
            tax: ''
          }
        },
        calculated_amount: 0,
        bunkerSuppliers: [],
        taxes: ['0%', '9%']
      };
    },
    methods: {
      submit() {
        return this.$refs.form.validate();
      },
      handleOperationInit() {
        this.populateSuppliers();
      },
      initServiceOptedDetails() {
        const bunkerService = _.find(this.servicesOpted, { bill_type: 'Bunker Service' });

        if (bunkerService?.card_id) {
          this.bunkerServiceOptForm = { ...bunkerService };
          this.bunkerServiceOptForm.metadata.price_per_metric_ton = parseFloat(this.bunkerServiceOptForm.metadata.price_per_metric_ton || 0).toFixed(2);
          this.bunkerServiceOptForm.metadata.no_of_metric_tons = parseFloat(this.bunkerServiceOptForm.metadata.no_of_metric_tons || 0).toFixed(2);
          this.bunkerServiceOptForm.amount = parseFloat(this.bunkerServiceOptForm.amount || 0).toFixed(2);
          this.calculated_amount = parseInt(this.bunkerServiceOptForm.metadata.price_per_metric_ton || 0) * parseInt(this.bunkerServiceOptForm.metadata.no_of_metric_tons || 0);
        }
      },
      calculateBunkerPrice() {
        this.calculated_amount = parseInt(this.bunkerServiceOptForm.metadata.price_per_metric_ton) * parseInt(this.bunkerServiceOptForm.metadata.no_of_metric_tons);
        this.bunkerServiceOptForm.amount = this.calculated_amount;
      },
      populateSuppliers() {
        this.$api.service_catalogs.get_suppliers_list('Bunker Service')
          .then((response) => {
            this.bunkerSuppliers = response.suppliers;
            this.bunkerServiceOptForm.service_catalog_id = response.service_catalog_id;
            this.initServiceOptedDetails();
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          });
      },
      saveDetails() {
        if(!this.submit()) return;
        this.loading = true;
        this.bunkerServiceOptForm = Object.assign(this.bunkerServiceOptForm, {
          operation_id: this.operation.id
        });

        Promise.all([
            this.$api.operation_billed_items.save_service(this.bunkerServiceOptForm),
          ]).then(() => {
            this.$store.dispatch('setAlert', {
              status: 'success',
              show: true,
              message: 'Bunker supplies updated successfully',
            });
          }).catch((err) => {
            this.$store.dispatch('setAlert', {
              status: 'error',
              show: true,
              message: err.message,
            });
          }).finally(() => (this.loading = false));
      }
    },
    computed: {
      hintText() {
        if (Number(this.bunkerServiceOptForm.amount) !== this.calculated_amount) {
          return `Total price differs from calculated amount \S$${this.calculated_amount}`;
        } else {
          return "";
        }
      },
      amountRules() {
        if (this.isDraft || (this.operation && this.operation.is_draft)) {
          return [this.rules.required, this.rules.negative];
        } else {
          return [this.rules.negative];
        }
      },
      supplierRules() {
        if (this.bunkerServiceOptForm.metadata.arrange_by_owner) {
          return [];
        } else {
          return [this.rules.required];
        }
      }
    },
    watch: {
      operation(value) {
        this.handleOperationInit();
      }
    },
    created() {
      this.handleOperationInit();
    },
  };
</script>